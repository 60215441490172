
import React from 'react';
import './careerHome.css'

const CareerHome = () => {
    return (
        <div className="career-section">
            <div className="career-container">
                <div className="career-content">
                    <div className="career-title-button">
                        <div className="career-section-title">
                            <div className="title">
                                <h1 style={{fontSize:"64px"}}>Join Our Team</h1>
                            </div>
                            <div className="description">
                                <p style={{fontSize: "16px", textAlign: "center", color: "black"}}>Our integrated calendar feature transforms your tasks and events into a dynamic to-do list, helping you manage your time more efficiently.</p>

                            </div>

                        </div>
                        {/*<Title title1="Features" title2="Experience the Calentask Advantage" title3="The award-winning calendar app with powerful features including intuitive natural language text parsing, beautiful full calendar day, week"/>*/}
                        <div style={{display: "contents"}}>
                            <div style={{
                                flex: "none",
                                height: "auto",
                                position: "relative",
                                width: "auto"
                            }}>
                                <a href="./contact">
                                    <div className="container">
                                        <p>See Job Details</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CareerHome;