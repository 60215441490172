import React from 'react';
import './benefits.css';

const Benefits = () => {
    return (
        <>

            <div className="benefits-section">
                <div className="container">
                    <div className="benefits-cards">

                        <div className="card">
                            <div className="icon">
                                <div style={{
                                    position: "absolute",
                                    borderRadius: "inherit",
                                    inset: 0
                                }}>
                                    <img src="https://framerusercontent.com/images/iJ20MqRAZCwgJHNo1XOIdHP5ds.svg"/>


                                </div>
                            </div>
                            <div className="content">
                                <div className="rich-text">
                                    <p style={{
                                        color: "rgb(10, 9, 21)",
                                        fontSize: "24px",fontWeight:450,
                                        textAlign: "left"
                                    }}>Fixed Time and Price
                                    </p>

                                </div>
                                <div className="rich-text">
                                    <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>With this model, we work closely with you to define clear deliverables and timelines. Together, we set a fixed price for the project, so you know exactly what to expect from start to finish.</p>

                                </div>

                            </div>

                        </div>
                        <div className="card">
                            <div className="icon">
                                <div style={{
                                    position: "absolute",
                                    borderRadius: "inherit",
                                    inset: 0
                                }}>
                                    <img src="https://framerusercontent.com/images/lPw0HL65L84BQKs0LCL3ASqRanU.svg"/>


                                </div>
                            </div>
                            <div className="content">
                                <div className="rich-text">
                                    <p style={{
                                        color: "rgb(10, 9, 21)",fontWeight:450,
                                        fontSize: "24px",
                                        textAlign: "left"
                                    }}>Time and Material
                                    </p>

                                </div>
                                <div className="rich-text">
                                    <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>Ideal for complex or evolving projects, our time & material model provides flexibility. This approach allows for adjustments in specifications and design, ensuring we meet your needs as they evolve.</p>

                                </div>

                            </div>

                        </div>
                        <div className="card">
                            <div className="icon">
                                <div style={{
                                    position: "absolute",
                                    borderRadius: "inherit",
                                    inset: 0
                                }}>
                                    <img src="https://framerusercontent.com/images/pbr0sHJvcX8pljHFyuETsf74rTQ.svg"/>


                                </div>
                            </div>
                            <div className="content">
                                <div className="rich-text">
                                    <p style={{
                                        color: "rgb(10, 9, 21)",
                                        fontSize: "24px",fontWeight:450,
                                        textAlign: "left"
                                    }}>Milestone Billing
                                    </p>

                                </div>
                                <div className="rich-text">
                                    <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>For long-term projects, milestone billing keeps everything on track. We divide the project into measurable phases, giving you visibility into progress and reducing risks along the way.</p>

                                </div>

                            </div>

                        </div>
                        <div className="card">
                            <div className="icon">
                                <div style={{
                                    position: "absolute",
                                    borderRadius: "inherit",
                                    inset: 0
                                }}>
                                    <img src="https://framerusercontent.com/images/pbr0sHJvcX8pljHFyuETsf74rTQ.svg"/>


                                </div>
                            </div>
                            <div className="content">
                                <div className="rich-text">
                                    <p style={{
                                        color: "rgb(10, 9, 21)",
                                        fontSize: "24px",fontWeight:450,
                                        textAlign: "left"
                                    }}>Dedicated Resource
                                    </p>

                                </div>
                                <div className="rich-text">
                                    <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>If you need ongoing support, our dedicated resource model provides you with a specialized team working exclusively on your project. This team can implement customized quality and process frameworks directly at your end.</p>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default Benefits;
