import React from "react";

const VisionAndMission = () => {
    return (
        <div>
            <div className='section'>
                <div className='sub-container' style={{flexDirection:"row",padding:"30px"}}>
                    <div className='title' style={{width:"45%",backgroundColor:"#f7f6f4",borderRadius:"30px",padding:"30px",height:"300px",justifyContent:"left",textAlign:"left"}}>
                        <h1>Our Vision</h1>
                        <p style={{fontSize: "20px",marginTop:"20px",textAlign:"left"}}>We’re dedicated to driving continuous innovation. By combining
                            advanced software development with strategic consulting, we deliver powerful solutions that
                            maximize business potential and promote sustainable growth.
                        </p>
                    </div>
                    <div className='title' style={{width:"45%" ,backgroundColor:"#f7f6f4",borderRadius:"30px",padding:"30px",height:"300px",justifyContent:"left",textAlign:"left"}}>
                        <h1>Our Mission</h1>
                        <p style={{fontSize: "20px",marginTop:"20px",textAlign:"left"}}>Our mission is to empower businesses with custom-built, scalable technology solutions tailored to their unique needs. Through services like Custom Web App Development, WordPress Development, E-Commerce Development, Business Process Automation, and Custom Business Portals, we build lasting partnerships with our clients, fueled by industry expertise and a relentless commitment to excellence.

                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VisionAndMission;