import React from 'react';
import './testimonialCard.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt, faStar as faStarOutline } from '@fortawesome/free-solid-svg-icons';

const TestimonialCard = ({ name, location, role, rating, text, imageUrl }) => {

    const MAX_STARS = 5;

    return (
        <div className="testimonial-card">
            <div className="testimonial-card-header">
                <div className="testimonial-user-info">
                    <img src={imageUrl} alt={name} className="testimonial-avatar"/>

                    <div style={{alignItems:"left"}}>
                        <p className="testimonial-name">{name}</p>
                        <p className="testimonial-location">{location}</p>
                        {role && <p className="testimonial-role">{role}</p>}
                    </div>

                </div>
                <div style={{display: "flex",
                    width: "70%",
                    gap: "15px"}}>
                    <p className="testimonial-text">{text}</p>


                </div>

            </div>
            <div className="testimonial-footer">
                <div className="google-rating">
                    <img src="google.avif" alt="Google" className="google-logo"/>
                    <span className="rating-text">{rating}/5 rating</span>
                </div>
                <div className="testimonial-stars">
                    {Array.from({length: MAX_STARS}).map((_, index) => (
                        <FontAwesomeIcon
                            key={index}
                            icon={index < rating ? faStar : faStarOutline}
                            style={{color: index < rating ? '#e82030' : '#d3d3d3', marginRight: '4px'}}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TestimonialCard;
