

import React from 'react';

import './serviceSection.css'
const ServiceMain = ({img}) => {
    return (
        <>
            <div className="service-section">
                <div className="container">
                    <div className="content">
                        <div className="image">
                            <div style={{position: "absolute",
                                borderRadius: "inherit",
                                inset: 0}}>
                                <img style={{display: "block",
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "inherit",
                                    objectPosition: "center center",
                                    objectFit: "cover"
                                }} src={img} alt=""/>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}
export default ServiceMain;