
import React, {useEffect, useRef} from 'react';
import './Tesimonial.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TestimonialCard from "../TestimonialCard";

const TestimonialSection = (props) => {
    const sliderRef = useRef(null);

    useEffect(() => {
        if (sliderRef.current) {
            const slickList = sliderRef.current.querySelector('.slick-list');
            if (slickList) {
                slickList.style.height = '400px';
            }
            const slickTrack = sliderRef.current.querySelector('.slick-track');
            if (slickTrack) {
                slickTrack.style.height = '400px';
            }
        }
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,

        slidesToScroll: 1,
        adaptiveHeight: true, // This will adapt the slider height to each slide's content
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };


    const testimonials = [
        {
            name: "Jennifer Lynn",
            location: "Los Angeles",
            text: "The integration of tasks and calendar events into one platform has made it so much easier to stay on top of everything.",
            rating: 3.6,
            imageUrl: `/avatar1.avif`
        },
        {
            name: "Jennifer Lynn",
            location: "Los Angeles",
            text: "The integration of tasks and calendar events into one platform has made it so much easier to stay on top of everything.",
            rating: 2.6,
            imageUrl: "/avatar2.avif"
        },{
            name: "Jennifer Lynn",
            location: "Los Angeles",
            text: "The integration of tasks and calendar events into one platform has made it so much easier to stay on top of everything.",
            rating: 4.6,
            imageUrl: "/avatar3.avif"
        },{
            name: "Jennifer Lynn",
            location: "Los Angeles",
            text: "The integration of tasks and calendar events into one platform has made it so much easier to stay on top of everything.",
            rating: 4.6,
            imageUrl: "https://via.placeholder.com/150"
        },
    ];

    return (
        <div className="testimonial-section">
            <div className="container">
                <div className="content">
                    <div className="section-title">
                        <div style={{display: "contents"}}>
                            <div style={{
                                flex: "none",
                                height: "auto",
                                position: "relative",
                                width: "auto"
                            }}>
                                <div className="default">
                                    <div className="rich-text">
                                        <p style={{color: "black", fontSize: "12px"}}>testimonials</p>

                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="title">
                            <h1 style={{textAlign: "center", color: "black", fontSize: "48px"}}>What Our Users Are
                                Saying</h1>
                        </div>

                    </div>
                    {/*<div style={{display: "contents"}}>*/}
                    {/*    <div className="container">*/}
                    {/*        <div className="desktop-tab"></div>*/}

                    {/*    </div>*/}

                    {/*</div>*/}
                    <div className="slider-wrapper" ref={sliderRef}>
                        <Slider {...settings}>
                            {testimonials.map((testimonial, index) => (
                                    <TestimonialCard
                                        key={index}
                                        name={testimonial.name}
                                        location={testimonial.location}
                                        role={testimonial.role}
                                        text={testimonial.text}
                                        rating={testimonial.rating}
                                        imageUrl={testimonial.imageUrl}
                                    />

                            ))}
                        </Slider>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default TestimonialSection;