import React from 'react';
import CareerHome from "../CareerHome";
import Services from "../Services";
import ServiceMain from "../ServiceMain";
import IntroductionSection from "../IntroductionSection";
import Benefits from "../Benefits";
import Title from "../Title";
import Jobs from "../Jobs";

const Career = () => {
    return(
        <>
        <CareerHome/>
            <ServiceMain img={"https://framerusercontent.com/images/6IXep0yQnItk2ItO2Ut2JZb5k7Q.png"}/>
            <IntroductionSection left={"All that you've committed to, all in one spot."}
            rightTitle={"ESTABLISHED IN 1997"}
            rightDes={"CalenTask uniquely combines task management, event scheduling, and contact organization into one seamless experience. Our integrated calendar feature transforms your tasks and events into a dynamic to-do list, helping you manage your time more efficiently."}/>
            <Benefits/>
            {/*<Title title2={"Open Positions"}/>*/}
            {/*<Jobs/>*/}

        </>
    )
}
export default Career;