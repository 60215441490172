
import React from 'react';
import './coreValues.css'

const CoreValues = () => {
    return (
        <div className="introduction-section">
            <div className="container">
                <div className="core-values-card-list">
                    <div className="card">
                        {/*<div className="icon">*/}
                        {/*    <div style={{*/}
                        {/*        position: "absolute",*/}
                        {/*        borderRadius: "inherit",*/}
                        {/*        inset: 0*/}
                        {/*    }}>*/}
                        {/*        <img*/}
                        {/*            src="https://framerusercontent.com/images/Rwi5kGBHf7EV429aEFELg31H27g.svg"/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="content">
                            <div className="rich-text">
                                <p style={{
                                    color: "rgb(10, 9, 21)",
                                    fontSize: "20px",
                                    textAlign: "left"
                                }}>Unified</p>

                            </div>
                            <div className="rich-text">
                                <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>We operate as one—aligned in vision, execution, and outcomes. As a leader in software development, we create advanced, tailor-made solutions that enable businesses to thrive in the digital world.</p>

                            </div>

                        </div>

                    </div>
                    <div className="card">
                        {/*<div className="icon">*/}
                        {/*    <div style={{*/}
                        {/*        position: "absolute",*/}
                        {/*        borderRadius: "inherit",*/}
                        {/*        inset: 0*/}
                        {/*    }}>*/}
                        {/*        <img*/}
                        {/*            src="https://framerusercontent.com/images/Q1Z8FhRWa3maYjYzCXOqj4LoMjE.svg"/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="content">
                            <div className="rich-text">
                                <p style={{
                                    color: "rgb(10, 9, 21)",
                                    fontSize: "20px",
                                    textAlign: "left"
                                }}>Confident</p>

                            </div>
                            <div className="rich-text">
                                <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>Confidence fuels our approach. Every project we undertake—from custom web applications to automation solutions—reflects our commitment to delivering reliable, innovative, and impactful results that help businesses grow and excel.
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className="card">
                        {/*<div className="icon">*/}
                        {/*    <div style={{*/}
                        {/*        position: "absolute",*/}
                        {/*        borderRadius: "inherit",*/}
                        {/*        inset: 0*/}
                        {/*    }}>*/}
                        {/*        <img src="https://framerusercontent.com/images/hyfhSrmY5D9gFbsMCa0PhmPWu8.svg"/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="content">
                            <div className="rich-text">
                                <p style={{
                                    color: "rgb(10, 9, 21)",
                                    fontSize: "20px",
                                    textAlign: "left"
                                }}>Reliable

                                </p>

                            </div>
                            <div className="rich-text">
                                <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>Reliability is the cornerstone of our work. At Unified Infotech, we provide dependable, end-to-end digital transformation solutions, including e-commerce platforms and custom business portals, to ensure resilience and growth in a fast-changing world.
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className="card">
                        {/*<div className="icon">*/}
                        {/*    <div style={{*/}
                        {/*        position: "absolute",*/}
                        {/*        borderRadius: "inherit",*/}
                        {/*        inset: 0*/}
                        {/*    }}>*/}
                        {/*        <img src="https://framerusercontent.com/images/2RIIFVxu1NpJhUXRB9IWtfcXDE.svg"/>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="content">
                            <div className="rich-text">
                                <p style={{
                                    color: "rgb(10, 9, 21)",
                                    fontSize: "20px",
                                    textAlign: "left"
                                }}>Transformative</p>

                            </div>
                            <div className="rich-text">
                                <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>Our commitment to transformation drives us. Through pioneering software solutions, Unified Infotech empowers businesses to achieve remarkable growth, innovate, and lead in an evolving digital landscape.</p>

                            </div>

                        </div>

                    </div>
                    {/*<div className="card">*/}
                    {/*    /!*<div className="icon">*!/*/}
                    {/*    /!*    <div style={{*!/*/}
                    {/*    /!*        position: "absolute",*!/*/}
                    {/*    /!*        borderRadius: "inherit",*!/*/}
                    {/*    /!*        inset: 0*!/*/}
                    {/*    /!*    }}>*!/*/}
                    {/*    /!*        <img*!/*/}
                    {/*    /!*            src="https://framerusercontent.com/images/eg8ZeUd9QmNQsbkcBKXubkIB1lw.svg"/>*!/*/}
                    {/*    /!*    </div>*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*    <div className="content">*/}
                    {/*        <div className="rich-text">*/}
                    {/*            <p style={{*/}
                    {/*                color: "rgb(10, 9, 21)",*/}
                    {/*                fontSize: "20px",*/}
                    {/*                textAlign: "left"*/}
                    {/*            }}>Collaboration</p>*/}

                    {/*        </div>*/}
                    {/*        <div className="rich-text">*/}
                    {/*            <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>The*/}
                    {/*                top calendar app boasting sophisticated tools and </p>*/}

                    {/*        </div>*/}

                    {/*    </div>*/}

                    {/*</div>*/}
                    {/*<div className="card">*/}
                    {/*    /!*<div className="icon">*!/*/}
                    {/*    /!*    <div style={{*!/*/}
                    {/*    /!*        position: "absolute",*!/*/}
                    {/*    /!*        borderRadius: "inherit",*!/*/}
                    {/*    /!*        inset: 0*!/*/}
                    {/*    /!*    }}>*!/*/}
                    {/*    /!*        <img*!/*/}
                    {/*    /!*            src="https://framerusercontent.com/images/lyXr3ndeYblB3P0bzkjFtbpjnNk.svg"/>*!/*/}
                    {/*    /!*    </div>*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*    <div className="content">*/}
                    {/*        <div className="rich-text">*/}
                    {/*            <p style={{*/}
                    {/*                color: "rgb(10, 9, 21)",*/}
                    {/*                fontSize: "20px",*/}
                    {/*                textAlign: "left"*/}
                    {/*            }}>Data-Driven</p>*/}

                    {/*        </div>*/}
                    {/*        <div className="rich-text">*/}
                    {/*            <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>The*/}
                    {/*                highly praised calendar app with dynamic features and</p>*/}

                    {/*        </div>*/}

                    {/*    </div>*/}

                    {/*</div>*/}
                    {/*<div className="card">*/}
                    {/*    /!*<div className="icon">*!/*/}
                    {/*    /!*    <div style={{*!/*/}
                    {/*    /!*        position: "absolute",*!/*/}
                    {/*    /!*        borderRadius: "inherit",*!/*/}
                    {/*    /!*        inset: 0*!/*/}
                    {/*    /!*    }}>*!/*/}
                    {/*    /!*        <img src="https://framerusercontent.com/images/TANxYvB50Yd5dJCjp6E1DEwZGM.svg"/>*!/*/}
                    {/*    /!*    </div>*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*    <div className="content">*/}
                    {/*        <div className="rich-text">*/}
                    {/*            <p style={{*/}
                    {/*                color: "rgb(10, 9, 21)",*/}
                    {/*                fontSize: "20px",*/}
                    {/*                textAlign: "left"*/}
                    {/*            }}>Scalability for Growth</p>*/}

                    {/*        </div>*/}
                    {/*        <div className="rich-text">*/}
                    {/*            <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>*/}
                    {/*                The acclaimed calendar app with versatile features and benefits</p>*/}

                    {/*        </div>*/}

                    {/*    </div>*/}

                    {/*</div>*/}
                    {/*<div className="card">*/}
                    {/*    /!*<div className="icon">*!/*/}
                    {/*    /!*    <div style={{*!/*/}
                    {/*    /!*        position: "absolute",*!/*/}
                    {/*    /!*        borderRadius: "inherit",*!/*/}
                    {/*    /!*        inset: 0*!/*/}
                    {/*    /!*    }}>*!/*/}
                    {/*    /!*        <img src="https://framerusercontent.com/images/he8pbnzIkk83ZT5U6v0Rpv9H8g.svg"/>*!/*/}


                    {/*    /!*    </div>*!/*/}
                    {/*    /!*</div>*!/*/}
                    {/*    <div className="content">*/}
                    {/*        <div className="rich-text">*/}
                    {/*            <p style={{*/}
                    {/*                color: "rgb(10, 9, 21)",*/}
                    {/*                fontSize: "20px",*/}
                    {/*                textAlign: "left"*/}
                    {/*            }}>Sustainability</p>*/}

                    {/*        </div>*/}
                    {/*        <div className="rich-text">*/}
                    {/*            <p style={{color: "rgb(82, 82, 90)", fontSize: "16px", textAlign: "left"}}>The*/}
                    {/*                best calendar app featuring enhanced tools and </p>*/}

                    {/*        </div>*/}

                    {/*    </div>*/}

                    {/*</div>*/}


                </div>
            </div>
        </div>
    )
}
export default CoreValues;