import React, { useState } from 'react';
import './header.css';
import {useNavigate} from "react-router-dom";

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/contact-us');
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    document.addEventListener('scroll', () => {
        const header = document.querySelector('.header');

        if (window.scrollY > 0) {
            header.classList.add('scrolled');
        }
        else(header.classList.remove('scrolled'));
    });
    return (
        <header className="header">
            <div className="logo">
                <img src='/code1.png' alt="logo" style={{height: "60px"}}/>
            </div>
            <div className="nav-bar">
                {/* Hamburger Icon for Mobile */}
                {/*<div className="hamburger" onClick={toggleMenu}>*/}
                {/*    <span></span>*/}
                {/*    <span></span>*/}
                {/*    <span></span>*/}
                {/*</div>*/}
                <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                {/* Menu Items */}
                <ul className={`menu-items ${isMenuOpen ? 'show' : ''}`}>
                    <li className="nav-item"><a href='/'>Home</a></li>
                    <li className="nav-item"><a href='/services'>Our Services</a></li>
                    <li className="nav-item"><a href='/careers'>Work with us</a></li>
                    <li className="nav-item"><a href='/about'>About Us</a></li>
                    <li></li>
                </ul>

                {/* Get Template Button (visible on desktop only) */}
                <div className="menu-items">
                    <div className="nav-item">
                        <button className='header-button' style={{cursor: "pointer"}} onClick={handleNavigate}>Contact Us</button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
