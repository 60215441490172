import React from 'react';
import './contactForm.css'
const ContactForm = () => {
    return (
        <>

            <div className="contact-section">
                <div className="container">
                    <div className="content">
                        <div className="contact-info">
                            <div className="left">
                                <div className="content">
                                    <div className="title-paragraph">
                                        <div className="icon-title">

                                            <div className="icon">
                                                <div style={{
                                                    position: "absolute",
                                                    borderRadius: "inherit",
                                                    inset: 0
                                                }}>
                                                    <img
                                                        src="https://framerusercontent.com/images/AvnqAwb7J8t7TyLLcPaU379dRE.svg"/>
                                                </div>
                                            </div>
                                            <div className="rich-text">
                                                <p style={{fontSize: "20px",textAlign:"left"}}>Contact Email Address</p>
                                            </div>
                                        </div>
                                        <div className="rich-text">

                                            <p style={{textAlign:"left"}}>For any inquiries, support, or feedback, feel free to email us. Our team
                                                will get back to you within 24-48 hours.</p>
                                        </div>

                                    </div>
                                    <div className="rich-text">
                                        <p style={{textAlign:"left"}}><a href="mailto: info@codelead.lk" target="_blank" rel="noopener">
                                            info@codelead.lk
                                            </a></p>

                                        </div>

                                    </div>
                                    <div className="content">
                                    <div className="title-paragraph">
                                        <div className="icon-title">
                                            <div className="icon">
                                                <div style={{
                                                    position: "absolute",
                                                    borderRadius: "inherit",
                                                    inset: 0
                                                }}>
                                                    <img
                                                        src="https://framerusercontent.com/images/5fRcgQg7sP2dWzbUiQ992zGoFEU.svg"/>
                                                </div>
                                            </div>
                                            <div className="rich-text">
                                                <p style={{fontSize: "20px",textAlign:"left"}}>Contact Phone

                                                </p>
                                            </div>
                                        </div>
                                        <div className="rich-text">

                                            <p style={{textAlign:"left"}}>For any inquiries, support, or feedback, feel free to email us. Our team will get back to you within 24-48 hours.</p>
                                        </div>

                                    </div>
                                    <div className="rich-text">
                                        <p style={{textAlign:"left"}}><a href="tel: +94-705-894-780" target="_blank" rel="noopener">
                                            +94 70 589 4780  / +94 11 217 5690
                                        </a></p>

                                    </div>

                                </div>
                                <div className="content">
                                    <div className="title-paragraph">
                                        <div className="icon-title">

                                            <div className="icon">
                                                <div style={{
                                                    position: "absolute",
                                                    borderRadius: "inherit",
                                                    inset: 0
                                                }}>
                                                    <img
                                                        src="https://framerusercontent.com/images/olT5U66Z1Xlip7wYx99f0h7ZL4s.svg"/>
                                                </div>
                                            </div>
                                            <div className="rich-text">
                                                <p style={{fontSize: "20px",textAlign:"left"}}>Address</p>
                                            </div>
                                        </div>
                                        <div className="rich-text">

                                            <p style={{textAlign:"left"}}>For any inquiries, support, or feedback, feel free to email us. Our team
                                                will get back to you within 24-48 hours.</p>
                                        </div>

                                    </div>
                                    <div className="rich-text">
                                        <p style={{textAlign:"left"}}>CodeLead(Pvt)Ltd, No159/13, Premier Park, Mullegama, Homagama,Sri Lanka</p>

                                    </div>

                                </div>

                                </div>
                            <form className="form">
                                <div className="title-label">
                                    <div className="rich-text">
                                        <h3 style={{fontSize:"32px"}}>Tell us a bit about yourself</h3>

                                    </div>
                                    <div className="labels">
                                        <div className="label1">
                                            <div className="rich-text">
                                                <p style={{textAlign:"left", fontSize: "16px"}}>Full Name</p>
                                            </div>
                                            <div style={{flex: "0 0 auto",
                                                height: "auto",
                                                position: "relative",
                                                width: "100%"}}>
                                                <input style={{width:"95%"}} required type={"text"}/>
                                            </div>

                                        </div>
                                        <div className="label2-3">
                                            <div className="label2">
                                                <div className="rich-text">
                                                    <p style={{textAlign:"left", fontSize: "16px"}}>Email Address</p>
                                                </div>
                                                <div style={{
                                                    flex: "0 0 auto",
                                                    height: "auto",
                                                    position: "relative",
                                                    width: "100%"
                                                }}>
                                                    <input  required type={"email"}/>
                                                </div>

                                            </div>
                                            <div className="label3">
                                                <div className="rich-text">
                                                    <p style={{textAlign:"left", fontSize: "16px"}}>Phone</p>
                                                </div>
                                                <div style={{
                                                    flex: "0 0 auto",
                                                    height: "auto",
                                                    position: "relative",
                                                    width: "100%"
                                                }}>
                                                    <input  required type={"tel"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="label4">
                                            <div className="rich-text">
                                                <p style={{textAlign:"left", fontSize: "16px"}}>Required Service</p>
                                            </div>
                                            <div style={{
                                                flex: "0 0 auto",
                                                height: "auto",
                                                position: "relative",
                                                width: "100%"
                                            }}>
                                                <select required style={{
                                                    border: "none",
                                                    padding:"16px",
                                                    borderRadius:"10px",
                                                    fontSize:"16px",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    width: "100%"
                                                }}>
                                                    <option disabled value selected>Select your required services</option>
                                                    <option value="1">Service 1</option>
                                                    <option value="2">Service 2</option>

                                                </select>
                                            </div>

                                        </div>
                                        <div className="label5">
                                            <div className="rich-text">
                                                <p style={{textAlign:"left", fontSize: "16px"}}>Messages</p>
                                            </div>
                                            <div style={{
                                                flex: "0 0 auto",
                                                height: "auto",
                                                position: "relative",
                                                width: "100%"
                                            }}>
                                                <textarea required name={"type a message"} style={{display: "flex",fontSize:"16px",
                                                    resize: "vertical",
                                                    overflowY: "auto",
                                                    padding:"16px",
                                                    borderRadius:"10px",
                                                    border:"none",

                                                    minHeight: "inherit",
                                                    maxHeight: "inherit",
                                                    whiteSpace: "break-spaces",overflow:"hidden",width:"95%"}}></textarea>

                                            </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="button">
                                        <div style={{
                                            flex: "0 0 auto",
                                            height: "auto",
                                        position: "relative",
                                        width: "auto"
                                    }}>
                                        <button>
                                            <div className="rich-text"><p style={{color: "white", fontSize: "16px"}}>Submit</p></div>
                                            <div className="arrow-icon">
                                                <div style={{
                                                    flex: "0 0 auto",
                                                    height: "18px",
                                                    left: "calc(50% - 9px)",
                                                    position: "absolute",
                                                    top: "calc(50% - 9px)",
                                                    width: "18px"}}>

                                                    <div style={{display: "contents"}}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                             viewBox="0 0 24 24" fill="none"
                                                             stroke="var(--token-5c20baf6-5762-424d-9aef-a1fba661ff17, rgb(255, 255, 255))"
                                                             strokeWidth="2" strokeLinecap="round"
                                                             strokeLinejoin="round" style={{width: "100%", height: "100%"}}>
                                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                                            <polyline points="12 5 19 12 12 19"></polyline>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}
export default ContactForm;