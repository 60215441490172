
import React from 'react';
import FrequentQuections from "../FrequentQuections";
import ServiceMain from "../ServiceMain";
import IntroductionSection from "../IntroductionSection";
import Counter from "../Counter";
import Title from "../Title";
import Benefits from "../Benefits";
import Home2Des from "../Home2Des";

const Services = () => {
    return (
        <>

            <ServiceMain img={"https://framerusercontent.com/images/vJsuzZvwzA1nEBOoQBXOXtM0yqY.jpg"}/>
            <IntroductionSection left={"Our story began with a problem that we wanted to solve."}
                                 isFeature={false}
                                 rightDes={"CalenTask uniquely combines task management, event scheduling, and contact organization into one seamless experience. Our integrated calendar feature transforms your tasks and events into a dynamic to-do list, helping you manage your time more efficiently."}
                                 rightTitle={"ESTABLISHED IN 2021"}/>

            <Title title2="Services for Your Business Growth"/>

            <Home2Des itemList={
                {
                    items: [
                        {
                            icon: "/Custom Web App Development.png",
                            iconText: "Custom Web App Development",
                            description: "Unlock powerful web solutions tailored to your needs with our expert custom web app development services.",
                        },
                        {
                            icon: "/Wordpress Development.png",
                            iconText: "Wordpress Development",
                            description: "Transform your ideas into a dynamic, fully customizable WordPress site built to enhance your online presence.",
                        },
                        {
                            icon: "/E-Commerce Development.png",
                            iconText: "E-Commerce Development",
                            description: "Boost your eCommerce platform with custom integrations, automation, and personalized features for optimized performance.",
                        },
                        {
                            icon: "/Business Process Automation.png",
                            iconText: "Business Process Automation",
                            description: "Streamline daily tasks with automated solutions crafted to make your business more efficient and productive.",
                        },
                        {
                            icon: "/Custom Business Portals.png",
                            iconText: "Custom Business Portals",
                            description: "Build a custom portal designed to meet your unique business requirements and goals, enhancing connectivity and collaboration.",
                        },
                        {
                            icon: "/Consultancy & Discovery.png",
                            iconText: "Consultancy & Discovery",
                            description: "Gain valuable insights and strategic guidance to help your business adopt the right technologies and make impactful decisions.",
                        },
                        {
                            icon: "/Maintenance & Supports.png",
                            iconText: "Maintenance & Supports",
                            description: "Count on our reliable maintenance and support to keep your business software running smoothly for long-term success.",
                        }
                    ]
                }
            }
            />


            <Counter/>

            <Title title2="Our Engagement Models" title3="We make it easy to collaborate with us by offering a range of flexible engagement models. Choose the one that best fits the needs of your project"/>
            <Benefits/>

            <FrequentQuections/>
        </>
    )
}
export default Services;