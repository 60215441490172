import './App.css';
import Header from "./Components/Header";
import {Route, Routes} from "react-router-dom";
import Home from "./Components/Home";
import Footer from "./Components/Footer";
import Services from "./Components/Services";
import About from "./Components/About";
import Career from "./Components/Career";
import Contact from "./Components/Contact";

function App() {


  return (
      <>
        <Header/>
          <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/services" element={<Services/>}/>
              <Route path="/about" element={<About/>}/>
              <Route path="/careers" element={<Career/>}/>
              <Route path="/contact-us" element={<Contact/>}/>
          </Routes>
          <Footer/>

      </>



  );
}

export default App;
