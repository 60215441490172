
import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="content">
                    <div className="top">
                        <div className="text">
                            <div style={{flex: "none",
                                height: "auto",
                                position: "relative",
                                width: "auto"}}>
                                <a href='./'>
                                    <div style={{flex: "none",
                                        height: "37px",
                                        position: "relative",
                                        width: "135px"}}>
                                        <div style={{position: "absolute",
                                            borderRadius: "inherit",
                                            inset: 0}}>
                                            <img src={'/code1.png'} alt="codelead"/>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="icon">
                            <div style={{
                                flex: "none",
                                height: "auto",
                                position: "relative",
                                width: "auto"
                            }}>
                                <a href="https://linkedin.com/">
                                    <div style={{
                                        flex: "none",
                                        height: "20px",
                                        overflow: "hidden",
                                        position: "relative",
                                        width: "20px"
                                    }}>
                                        <div style={{
                                            flex: "none",
                                            height: "20px",
                                            left: "calc(50.00000000000002% - 20px / 2)",
                                            position: "absolute",
                                            top: "calc(50.00000000000002% - 20px / 2)",
                                            width: '20px'
                                        }}>
                                            <div style={{
                                                position: "absolute",
                                                borderRadius: "inherit",
                                                inset: 0
                                            }}>
                                                <img
                                                    src="https://framerusercontent.com/images/7Niw1iQJk6fa0Y4jyZvlxz5SYs.svg"
                                                    sizes="20px"/>

                                            </div>

                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div style={{
                                flex: "none",
                                height: "auto",
                                position: "relative",
                                width: "auto"
                            }}>
                                <a href="https://instagram.com/">
                                    <div style={{
                                        flex: "none",
                                        height: "20px",
                                        overflow: "hidden",
                                        position: "relative",
                                        width: "20px"
                                    }}>
                                        <div style={{
                                            flex: "none",
                                            height: "20px",
                                            left: "calc(50.00000000000002% - 20px / 2)",
                                            position: "absolute",
                                            top: "calc(50.00000000000002% - 20px / 2)",
                                            width: '20px'
                                        }}>
                                            <div style={{
                                                position: "absolute",
                                                borderRadius: "inherit",
                                                inset: 0
                                            }}>
                                                <img
                                                    src="https://framerusercontent.com/images/2f8WrQWcQxgnp3VxzxW1U6uIc1Y.svg"
                                                    sizes="20px"/>

                                            </div>

                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div style={{
                                flex: "none",
                                height: "auto",
                                position: "relative",
                                width: "auto"
                            }}>
                                <a href="https://aat.com/">
                                    <div style={{
                                        flex: "none",
                                        height: "20px",
                                        overflow: "hidden",
                                        position: "relative",
                                        width: "20px"
                                    }}>
                                        <div style={{
                                            flex: "none",
                                            height: "20px",
                                            left: "calc(50.00000000000002% - 20px / 2)",
                                            position: "absolute",
                                            top: "calc(50.00000000000002% - 20px / 2)",
                                            width: '20px'
                                        }}>
                                            <div style={{
                                                position: "absolute",
                                                borderRadius: "inherit",
                                                inset: 0
                                            }}>
                                                <img
                                                    src="https://framerusercontent.com/images/c7gQGJ4EhJDxKQ1Cd5vgiFaHwQ.svg"
                                                    sizes="20px"/>

                                            </div>

                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div style={{
                                flex: "none",
                                height: "auto",
                                position: "relative",
                                width: "auto"
                            }}>
                                <a href="https://twitter.com/">
                                    <div style={{
                                        flex: "none",
                                        height: "20px",
                                        overflow: "hidden",
                                        position: "relative",
                                        width: "20px"
                                    }}>
                                        <div style={{
                                            flex: "none",
                                            height: "20px",
                                            left: "calc(50.00000000000002% - 20px / 2)",
                                            position: "absolute",
                                            top: "calc(50.00000000000002% - 20px / 2)",
                                            width: '20px'
                                        }}>
                                            <div style={{
                                                position: "absolute",
                                                borderRadius: "inherit",
                                                inset: 0
                                            }}>
                                                <img
                                                    src="https://framerusercontent.com/images/JUqJwlnQSl4EOjEBfTUBPrOkGs.svg"
                                                    sizes="20px"/>

                                            </div>

                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="menus">
                        <div className="menus-list1">
                            <div className="rich-text">
                                <p style={{
                                    fontSize: "16px",
                                    color: "rgb(10, 9, 21)",
                                    fontWeight: "bold"
                                }}>Navigation</p>
                            </div>
                            <div className="menu">
                                <div style={{
                                    flex: "none",
                                    height: "auto",
                                    position: "relative",
                                    width: "auto"
                                }}>
                                    <a href='/'>
                                        <div className="rich-text">
                                            <p style={{fontSize: "16px", color: "rgb(10, 9, 21)"}}>Home</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="menu">
                                <div style={{
                                    flex: "none",
                                    height: "auto",
                                    position: "relative",
                                    width: "auto"
                                }}>
                                    <a href='./services'>
                                        <div className="rich-text">
                                            <p style={{fontSize: "16px", color: "rgb(10, 9, 21)"}}>Our Services</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="menu">
                                <div style={{
                                    flex: "none",
                                    height: "auto",
                                    position: "relative",
                                    width: "auto"
                                }}>
                                    <a href='./careers'>
                                        <div className="rich-text">
                                            <p style={{fontSize: "16px", color: "rgb(10, 9, 21)"}}>Work with us</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="menu">
                                <div style={{
                                    flex: "none",
                                    height: "auto",
                                    position: "relative",
                                    width: "auto"
                                }}>
                                    <a href='./about'>
                                        <div className="rich-text">
                                            <p style={{fontSize: "16px", color: "rgb(10, 9, 21)"}}>About us</p>
                                        </div>
                                    </a>
                                </div>
                            </div>


                        </div>
                        <div className="menus-list1">
                            <div className="rich-text">
                                <p style={{
                                    fontSize: "16px",
                                    color: "rgb(10, 9, 21)",
                                    fontWeight: "bold"
                                }}>Resources</p>
                            </div>
                            <div className="menu">
                                <div style={{
                                    flex: "none",
                                    height: "auto",
                                    position: "relative",
                                    width: "auto"
                                }}>
                                    <a href='/'>
                                        <div className="rich-text">
                                            <p style={{fontSize: "16px", color: "rgb(10, 9, 21)"}}>Terms & privacy</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="menu">
                                <div style={{
                                    flex: "none",
                                    height: "auto",
                                    position: "relative",
                                    width: "auto"
                                }}>
                                    <a href='./services'>
                                        <div className="rich-text">
                                            <p style={{fontSize: "16px", color: "rgb(10, 9, 21)"}}>Privacy</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="menu">
                                <div style={{
                                    flex: "none",
                                    height: "auto",
                                    position: "relative",
                                    width: "auto"
                                }}>
                                    <a href='./careers'>
                                        <div className="rich-text">
                                            <p style={{fontSize: "16px", color: "rgb(10, 9, 21)"}}>Changelog</p>
                                        </div>
                                    </a>
                                </div>
                            </div>


                        </div>
                        <div className="menus-list1">
                            <div className="rich-text">
                                <p style={{
                                    fontSize: "16px",
                                    color: "rgb(10, 9, 21)",
                                    fontWeight: "bold"
                                }}>Company</p>
                            </div>
                            <div className="menu">
                                <div style={{
                                    flex: "none",
                                    height: "auto",
                                    position: "relative",
                                    width: "auto"
                                }}>
                                    <a href='/'>
                                        <div className="rich-text">
                                            <p style={{fontSize: "16px", color: "rgb(10, 9, 21)"}}>Careers</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="menu">
                                <div style={{
                                    flex: "none",
                                    height: "auto",
                                    position: "relative",
                                    width: "auto"
                                }}>
                                    <a href='./contact-us'>
                                        <div className="rich-text">
                                            <p style={{fontSize: "16px", color: "rgb(10, 9, 21)"}}>Contact</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="menu">
                                <div style={{
                                    flex: "none",
                                    height: "auto",
                                    position: "relative",
                                    width: "auto"
                                }}>
                                    <a href='/'>
                                        <div className="rich-text">
                                            <p style={{fontSize: "16px", color: "rgb(10, 9, 21)"}}>FAQs</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="menu">
                                <div style={{
                                    flex: "none",
                                    height: "auto",
                                    position: "relative",
                                    width: "auto"
                                }}>
                                    <a href='./about'>
                                        <div className="rich-text">
                                            <p style={{fontSize: "16px", color: "rgb(10, 9, 21)"}}>About us</p>
                                        </div>
                                    </a>
                                </div>
                            </div>


                        </div>


                    </div>

                </div>
                <div className="bottom-content">
                    <div className="rich-text">
                        <p style={{fontSize: "16px", color: "rgb(82, 82, 90)"}}>© CodeLead 2024 - All Rights Reserved</p>

                    </div>
                    <div style={{
                        backgroundColor: "rgb(82, 82, 90)",
                        opacity: 0.1, flex: "1 0 0px",
                        height: "1px",
                        overflow: "visible",
                        position: "relative",
                        width: "1px"
                    }}></div>
                </div>


            </div>

        </div>
    )
}
export default Footer;