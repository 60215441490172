import React from 'react';
import Title from "../Title";
import ContactForm from "../ContactForm";

const Contact = () => {
    return (
        <>
            <Title title2={"Get In Touch"}/>
            <ContactForm/>
        </>
    )
}
export default Contact;