import React from 'react';
import HomeTitle from "../HomeTitle";
import Title from "../Title";
import Home2Des from "../Home2Des";
import Home3Des from "../Home3Des";
import FeatureSection from "../FeatureSection";
import FeatureCTA from "../FeatureCTA";
import IntergrationSection from "../IntergrationSection";
import TestimonialSection from "../TestimonialSection/indes";
import FrequentQuections from "../FrequentQuections";
import HowProcessWork from "../HowProcessWork";


const Home = () => {


    const imageList = ['./Adobe XD Logotype.png',
        './angular.png',
        './AWS (Amazon Web Services) Logotype.png',
        './DigitalOcean Logotype.png',
        './Docker Logotype.png',
        './Git Logotype.png',
        './Google Cloud Logotype.png',
        './InVision Logotype.png',
        './Magento Logotype.png',
        './mysql.png',
        './nodejs.png',
        './Oracle Cloud Logotype.png',
        './php.png',
        './sass.png',
        './Shopify Logotype.png',
        './Sketch Logotype.png',
        './Visual Studio Code Logotype.png',
        './Wordpress Logotype.png'];
    return (<>
            <HomeTitle imageList={imageList}
                       h1={"We are a design, marketing, and technology company."}
                       sub={"We blend innovative design, strategic marketing, and advanced technology to help businesses thrive in the digital landscape."}
                       button1={"Contact us"}
                       button2={"Learn more"}

            />
            <Title title2="Services for Your Business Growth"/>

            <Home2Des itemList={
                {
                    items: [
                        {
                            icon: "/Custom Web App Development.png",
                            iconText: "Custom Web App Development",
                            description: "Unlock powerful web solutions tailored to your needs with our expert custom web app development services.",
                        },
                        {
                            icon: "/Wordpress Development.png",
                            iconText: "Wordpress Development",
                            description: "Transform your ideas into a dynamic, fully customizable WordPress site built to enhance your online presence.",
                        },
                        {
                            icon: "/E-Commerce Development.png",
                            iconText: "E-Commerce Development",
                            description: "Boost your eCommerce platform with custom integrations, automation, and personalized features for optimized performance.",
                        },
                        {
                            icon: "/Business Process Automation.png",
                            iconText: "Business Process Automation",
                            description: "Streamline daily tasks with automated solutions crafted to make your business more efficient and productive.",
                        },
                        {
                            icon: "/Custom Business Portals.png",
                            iconText: "Custom Business Portals",
                            description: "Build a custom portal designed to meet your unique business requirements and goals, enhancing connectivity and collaboration.",
                        },
                        {
                            icon: "/Consultancy & Discovery.png",
                            iconText: "Consultancy & Discovery",
                            description: "Gain valuable insights and strategic guidance to help your business adopt the right technologies and make impactful decisions.",
                        },
                        {
                            icon: "/Maintenance & Supports.png",
                            iconText: "Maintenance & Supports",
                            description: "Count on our reliable maintenance and support to keep your business software running smoothly for long-term success.",
                        }
                    ]
                }
            }
            />


            <Title title2="How Our Process Works"/>

            <HowProcessWork itemList={
                {
                    items: [
                        {
                            icon: "/process/ux-and-ui-design.svg",
                            iconText: "Product Analysis",
                            description: "Our comprehensive business analysis and feasibility studies provide a clear framework for achieving your business goals and meeting your specific needs." +
                                " Through in-depth market and competition research, we deliver valuable insights, including SWOT analysis, competitor profiling," +
                                " actionable recommendations, and detailed product comparisons." +
                                " We work with you to define precise project goals that align with your target market and audience," +
                                " ensuring that every step is tailored to maximize impact.\n" +
                                "Additionally, we present various technical solutions tailored to your requirements, budget, and vision, along with an accurate estimate of development time and costs.",
                        },
                        {
                            icon: "/process/planning.svg",
                            iconText: "Planning",
                            description: "We assemble a team of dedicated experts, each selected to ensure precise execution of specific tasks, driving your project toward success. Our planning process provides a clear roadmap for implementation, with a focus on safeguarding the achievement of your business goals. You’ll have full access to a suite of development tools, allowing for seamless progress monitoring and ensuring that every milestone is met with precision and transparency.",
                        },
                        {
                            icon: "/process/delivery.svg",
                            iconText: "Delivery",
                            description: "Our meticulous planning and structured client involvement ensure that deadlines are consistently met, providing a seamless delivery experience. Every phase of the software development process is designed to achieve predictable, high-quality results, enabling a faster time-to-market. We prioritize top-level data security and full regulatory compliance, safeguarding your project’s integrity.\nAdditionally, you’ll receive real-time updates from a dedicated account manager, keeping you informed and engaged every step of the way.",
                        },
                        {
                            icon: "/process/support.webp",
                            iconText: "Support & Maintenance",
                            description: "Our support and maintenance services ensure flawlessly functioning software, seamless integration, and minimized downtime. Enjoy fast and reliable assistance tailored to your selected package, with flexible bug-fixing warranties designed to meet your ongoing needs. Choose from a range of adaptable maintenance packages that allow you to switch seamlessly as your needs evolve. Our Premium package covers 95% of technical issues without additional costs, and 7 out of 10 clients opt to continue with one of our maintenance options.\n" +
                                "With our team by your side, you’ll experience certainty, safety, and unwavering support. We’re committed to standing by you, ensuring you’re never left alone with a newly developed product.",
                        }
                    ]
                }
            }/>

            {/*<Home3Des itemList={[*/}
            {/*    {*/}
            {/*        imageList:['/img1.avif','/img2.avif'],*/}
            {/*        color1:"#f99079",*/}
            {/*        color2:"rgba(252, 199, 190, 1)",*/}
            {/*        title1:"Contextual Linking",*/}
            {/*        title2:"Organize everything in one place easily",*/}
            {/*        description:"CalenTask acts as your second brain, where you can store and organize tasks, notes, ideas, and important information all in one easily accessible place.",*/}
            {/*        link:"Learn more"*/}
            {/*    },*/}
            {/*    {*/}
            {/*        imageList:['/img3.avif'],*/}
            {/*        color1:"#f976b5",*/}
            {/*        color2:"rgb(240, 144, 190)",*/}
            {/*        title1:"Connect Your Ideas",*/}
            {/*        title2:"Unified task and calendar management",*/}
            {/*        description:"Seamlessly integrate your tasks and events into a single calendar view, transforming your schedule into a dynamic to-do list.",*/}
            {/*        link:"Learn more"*/}
            {/*    },*/}
            {/*    {*/}
            {/*        imageList:['/img4.avif','/img6.avif'],*/}
            {/*        color1:"#3b38f5",*/}
            {/*        color2:"rgb(120, 118, 245)",*/}
            {/*        title1:"Find What You Need",*/}
            {/*        title2:"Share tasks, assign events, and collaborate",*/}
            {/*        description:"Share tasks, assign events, and collaborate with your team in real-time, enhancing productivity and communication.",*/}
            {/*        link:"Learn more"*/}
            {/*    }*/}

            {/*]*/}
            {/*} />*/}

            {/*<Title title1="FEATURES" title2="All-in-One Productivity Suite"/>*/}

            {/*<FeatureSection/>*/}
            <FeatureCTA/>
            {/*<IntergrationSection/>*/}
            <TestimonialSection/>
            <FrequentQuections/>

        </>
    )
}
export default Home;