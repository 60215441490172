

import React from "react";
import './featureCTA.css'

const FeatureCTA = () => {
    return (
        <div className="featureCTA-section">
            <div className="featureCTA-container">
                <div className="featureCTA-content">
                    <div className="featureCTA-title-button">
                        <div className="featureCTA-section-title">
                            {/*<div style={{display:"contents"}}>*/}
                            {/*    <div style={{flex: "none",*/}
                            {/*        height: "auto",*/}
                            {/*        position: "relative",*/}
                            {/*        width: "auto"}}>*/}
                            {/*        <div className="default">*/}
                            {/*            <div className="text">*/}
                            {/*                <p style={{fontSize:"12px",textAlign:"center",color:"white"}}>Features</p>*/}

                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="title">
                                <h1>Our Success Stories</h1>
                            </div>
                            <div className="description">
                                <p style={{fontSize:"16px",textAlign:"center",color:"white"}}>We proudly present our portfolio of successful projects, showcasing our expertise and the significant impact of our solutions.</p>

                            </div>

                        </div>
                        {/*<Title title1="Features" title2="Experience the Calentask Advantage" title3="The award-winning calendar app with powerful features including intuitive natural language text parsing, beautiful full calendar day, week"/>*/}
                        <div style={{display:"contents"}}>
                            <div style={{flex: "none",
                                        height: "auto",
                                        position: "relative",
                                        width: "auto"}}>
                                <a href="./contact-us">
                                    <div className="container">
                                        <p>Contact Us</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div style={{flex: "none", height: "auto", position: "relative", width: "106%", zIndex: 0}} >
                        <div className="desktop" style={{width: '100%', opacity: 1}}>
                            <div className="container" style={{opacity:1}}>
                                <div className="section">
                                    <ul>
                                        <li>
                                            <div className="image-box">
                                                <div className='image' style={{padding: "10px 5px", width:"220px !important"}}>
                                                    <img src="/agro.avif" style={{height: "220px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"270px !important"}}>
                                                    <img src="/navi.webp" style={{height: "270px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"330px !important"}}>
                                                    <img src="/plant.avif" style={{height: "330px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"220px !important"}}>
                                                    <img src="/yeh.avif" style={{height: "220px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"300px !important"}}>
                                                    <img src="/clear.avif" style={{height: "300px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"340px !important"}}>
                                                    <img src="/tea.avif" style={{height: "340px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"200px !important"}}>
                                                    <img src="/energy.avif" style={{height: "200px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"250px !important"}}>
                                                    <img src="/lig.webp" style={{height: "250px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"300px !important"}}>
                                                    <img src="/powr.avif" style={{height: "300px"}}/>
                                                </div>

                                            </div>
                                        </li>

                                        <li>
                                            <div className="image-box">
                                                <div className='image' style={{padding: "10px 5px", width:"220px !important"}}>
                                                    <img src="/agro.avif" style={{height: "220px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"270px !important"}}>
                                                    <img src="/navi.webp" style={{height: "270px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"330px !important"}}>
                                                    <img src="/plant.avif" style={{height: "330px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"220px !important"}}>
                                                    <img src="/yeh.avif" style={{height: "220px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"300px !important"}}>
                                                    <img src="/clear.avif" style={{height: "300px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"340px !important"}}>
                                                    <img src="/tea.avif" style={{height: "340px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"200px !important"}}>
                                                    <img src="/energy.avif" style={{height: "200px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"250px !important"}}>
                                                    <img src="/lig.webp" style={{height: "250px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"300px !important"}}>
                                                    <img src="/powr.avif" style={{height: "300px"}}/>
                                                </div>

                                            </div>
                                        </li>

                                        <li>
                                            <div className="image-box">
                                                <div className='image' style={{padding: "10px 5px", width:"220px !important"}}>
                                                    <img src="/agro.avif" style={{height: "220px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"270px !important"}}>
                                                    <img src="/navi.webp" style={{height: "270px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"330px !important"}}>
                                                    <img src="/plant.avif" style={{height: "330px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"220px !important"}}>
                                                    <img src="/yeh.avif" style={{height: "220px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"300px !important"}}>
                                                    <img src="/clear.avif" style={{height: "300px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"340px !important"}}>
                                                    <img src="/tea.avif" style={{height: "340px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"200px !important"}}>
                                                    <img src="/energy.avif" style={{height: "200px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"250px !important"}}>
                                                    <img src="/lig.webp" style={{height: "250px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"300px !important"}}>
                                                    <img src="/powr.avif" style={{height: "300px"}}/>
                                                </div>

                                            </div>
                                        </li>

                                        <li>
                                            <div className="image-box">
                                                <div className='image' style={{padding: "10px 5px", width:"220px !important"}}>
                                                    <img src="/agro.avif" style={{height: "220px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"270px !important"}}>
                                                    <img src="/navi.webp" style={{height: "270px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"330px !important"}}>
                                                    <img src="/plant.avif" style={{height: "330px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"220px !important"}}>
                                                    <img src="/yeh.avif" style={{height: "220px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"300px !important"}}>
                                                    <img src="/clear.avif" style={{height: "300px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"340px !important"}}>
                                                    <img src="/tea.avif" style={{height: "340px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"200px !important"}}>
                                                    <img src="/energy.avif" style={{height: "200px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"250px !important"}}>
                                                    <img src="/lig.webp" style={{height: "250px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"300px !important"}}>
                                                    <img src="/powr.avif" style={{height: "300px"}}/>
                                                </div>

                                            </div>
                                        </li>

                                        <li>
                                            <div className="image-box">
                                                <div className='image' style={{padding: "10px 5px", width:"220px !important"}}>
                                                    <img src="/agro.avif" style={{height: "220px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"270px !important"}}>
                                                    <img src="/navi.webp" style={{height: "270px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"330px !important"}}>
                                                    <img src="/plant.avif" style={{height: "330px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"220px !important"}}>
                                                    <img src="/yeh.avif" style={{height: "220px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"300px !important"}}>
                                                    <img src="/clear.avif" style={{height: "300px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"340px !important"}}>
                                                    <img src="/tea.avif" style={{height: "340px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"200px !important"}}>
                                                    <img src="/energy.avif" style={{height: "200px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"250px !important"}}>
                                                    <img src="/lig.webp" style={{height: "250px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"300px !important"}}>
                                                    <img src="/powr.avif" style={{height: "300px"}}/>
                                                </div>

                                            </div>
                                        </li>

                                        <li>
                                            <div className="image-box">
                                                <div className='image' style={{padding: "10px 5px", width:"220px !important"}}>
                                                    <img src="/agro.avif" style={{height: "220px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"270px !important"}}>
                                                    <img src="/navi.webp" style={{height: "270px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"330px !important"}}>
                                                    <img src="/plant.avif" style={{height: "330px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"220px !important"}}>
                                                    <img src="/yeh.avif" style={{height: "220px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"300px !important"}}>
                                                    <img src="/clear.avif" style={{height: "300px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"340px !important"}}>
                                                    <img src="/tea.avif" style={{height: "340px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"200px !important"}}>
                                                    <img src="/energy.avif" style={{height: "200px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"250px !important"}}>
                                                    <img src="/lig.webp" style={{height: "250px"}}/>
                                                </div>
                                                <div className='image' style={{padding: "10px 5px", width:"300px !important"}}>
                                                    <img src="/powr.avif" style={{height: "300px"}}/>
                                                </div>

                                            </div>
                                        </li>



                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="featuresCTA-bg">
                    <div style={{
                        position: "absolute",
                        borderRadius: "inherit",
                        inset: 0
                    }}>
                        <img alt="" style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                            borderRadius: "inherit",
                            objectPosition: "center center",
                            objectFit: "contain"
                        }} src="https://framerusercontent.com/images/7XUVcpJBtyXSPaTWsAremxnkGto.svg"/>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default FeatureCTA;