

import React, {useState} from 'react';

import './frequentSection.css';
import FAQAccordion from "../FAQAccordion";

const FrequentQuections = () => {
    const [openIndex, setOpenIndex] = useState(null); // State to manage which FAQ is open

    const faqs = [
        {
            icon: "/Ideation.png",
            question: "Ideation",
            answer: "We collaborate closely with clients to understand their vision, goals, and target audience. Through brainstorming and refinement, we shape ideas into products that align with business objectives and ROI targets."
        },
        {
            icon: "/Planning.png",
            question: "Planning",
            answer: "In this phase, we craft a tailored project framework that outlines essential functionalities, sets KPIs, estimates costs, and integrates risk and change management to keep projects on track and ensure successful delivery."
        },
        {
            icon: "/Design.png",
            question: "Design",
            answer: "Our design process selects the ideal tech stack and UI/UX design, creating a robust and visually engaging product structure that prioritizes exceptional user experiences."
        },
        {
            icon: "/Prototype.png",
            question: "Prototype",
            answer: "With a functional prototype, we bring the concept to life, allowing stakeholders to see and test the solution early on. This helps gather feedback and make improvements before full development."
        }
    ];


    const toggleFAQ = (index) => {
        setOpenIndex(index === openIndex ? null : index); // Toggle FAQ: Close if open, open if closed
    };
    return (
        <div className="FAQ-section">

            <div className="container">
                <div className="content">
                    <div className="section-title">
                        {/*<div style={{flex: "none",*/}
                        {/*    height: "auto",*/}
                        {/*    position: "relative",*/}
                        {/*    width: "auto"}}>*/}


                        {/*    <div className="default">*/}

                        {/*        <div className="rich-text" style={{opacity:0.7,width:"auto",whiteSpace:"pre",wordBreak:"normal",wordWrap:"normal"}}>*/}
                        {/*        <p style={{fontSize:"12px"}}>*/}
                        {/*            Frequent question*/}
                        {/*        </p>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="rich-text">
                            <h1 style={{color:"black"}}>From Concept to Success</h1>
                        </div>
                        <div className="rich-text" style={{opacity:0.8}}>
                            <p style={{color:"gb(82, 82, 90)",fontSize:"16px",textAlign:"left"}}>At CodeLead, we follow a structured, adaptable approach to ensure each software development project is scalable, secure, reliable, and budget-friendly. Our process is built to transform ideas into impactful, market-ready solutions that meet both strict budgets and evolving needs.</p>
                        </div>

                    </div>
                    <div style={{flex: "none",
                        height: "auto",
                        position: "relative",

                    }} className="width49">

                        <div className="desktop-q1">
                            {faqs.map((faq, index) => (
                                <FAQAccordion
                                    key={index}
                                    icon={faq.icon}
                                    question={faq.question}
                                    answer={faq.answer}
                                    isOpen={openIndex === index}
                                    onClick={() => toggleFAQ(index)}
                                />
                            ))}
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default FrequentQuections;