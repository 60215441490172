import React from 'react';
import './homeTitle.css'
import {useNavigate} from "react-router-dom";
const HomeTitle = ({h1,sub,button1,button2,imageList,isService}) => {

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/contact-us');
    };

    return (
        <div className='home-section'>
            <div className='home-container'>
                <div className='home-content'>
                    <div className="home-title-button">
                        <div className="home-title-text">
                            <div className="home-h1">
                                <h1>{h1}
                                </h1>
                            </div>
                            <div className="home-text">
                                <p style={{textAlign:"left",fontSize:"16px"}}>{sub}</p>
                            </div>
                        </div>
                        <div className="home-button">
                            <div className="nav-item">
                                <button className='header-button' type='submit' onClick={handleNavigate}>{button1}</button>
                            </div>
                            <div className="nav-item">
                                <button className='header-button-secondary' type='submit'>{button2}</button>
                            </div>
                        </div>
                    </div>
                    <div className="home-images">
                        <div className="home-layer"></div>
                        <div className="phone-mockup">
                            <div className="image-container">
                                <img src="/mymed.jpeg" alt="mymed"/>
                                <img src="/laptop-care.png" alt="laptop"/>

                                {/*<img src="https://framerusercontent.com/images/SeYRINWaPpxuNlp6Oh1XG2Ntwu8.png"*/}
                                {/*     alt="Image 1"/>*/}
                                {/*<img src="https://framerusercontent.com/images/bvrdc6XfeXONxOg5p44s3P288.png"*/}
                                {/*     alt="Image 2"/>*/}
                                {/*<img src="https://framerusercontent.com/images/e65pO1Wovve2EYwXl5VUTlauLw.png"*/}
                                {/*     alt="Image 3"/>*/}
                                {/*<img src="https://framerusercontent.com/images/yMs9U1fPavEPWAUV5ber1Uhw5A.png"*/}
                                {/*     alt="Image 4"/>*/}
                            </div>
                        </div>
                        <div className="home-phone">
                            <div className="home-phone-img">
                                <img src='/phone.avif  ' alt="phone"/>
                            </div>
                        </div>
                        <div className="home-layer-1"></div>
                    </div>
                </div>
            </div>
            {imageList && imageList.length > 0 && (
            <div className="image-ticker">
                <div className="image-track">
                    {imageList.map((item, index) => {
                        return (
                            <img src={item} alt={item}/>
                        )
                    })}
                    {imageList.map((item, index) => {
                        return (
                            <img src={item} alt={item}/>
                        )
                    })}
                    {imageList.map((item, index) => {
                        return (
                            <img src={item} alt={item}/>
                        )
                    })}
                </div>
            </div>
                )}

        </div>
    )
}
export default HomeTitle;