import React from 'react';
import './title.css'
const Title = ({title1,title2,title3}) => {
    return (
        <div className='section'>
                    <div className='sub-container'>
                        <div className='title'>
                            <p>{title1}</p>
                            <h1>{title2}</h1>
                            <p style={{paddingTop: "5px"}}>{title3}</p>
                        </div>
                    </div>
        </div>

    )
}

export default Title;